import { admin, admin2, adminPublic } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/memberHistory`
}

//= > 新增歷程
export const CreateHistory = async ({
  shopId,
  member,
  showMember,
  images,
  memberNote,
  note,
  file,
  fileName,
  fileDescription,
}) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}`,
    data: {
      member,
      showMember,
      images,
      memberNote,
      note,
      file,
      fileName,
      fileDescription,
    },
  })

  return res.data
}

//= > 取得歷程
export const GetHistory = async ({
  shopId,
  start,
  limit,
  MemberId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      MemberId,
    },
  })

  return res.data
}

//= > 取得歷程總數
export const GetHistoryCount = async ({
  shopId,
  MemberId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      MemberId,
    },
  })

  return res.data.count
}

//= > 查找指定歷程
export const FindHistory = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新歷程
export const UpdateHistory = async ({
  shopId,
  id,
  showMember,
  images,
  memberNote,
  note,
  file,
  fileName,
  fileDescription,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      showMember,
      images,
      memberNote,
      note,
      file,
      fileName,
      fileDescription,
    },
  })

  return res.data
}

//= > 刪除歷程
export const DeleteHistory = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

export const GetMemberHistoryFile = async ({
  shopId,
  id,
  fileFreeDownloadKey,
}) => {
  const res = await adminPublic({
    method: 'POST',
    url: `${baseUrl(shopId)}/${id}/freeDownloadFileSignedUrl`,
    data: {
      fileFreeDownloadKey,
    },
  })
  return res.data
}

export const GetHistoryExport = async ({
  shopId,
  limit = 100,
  start = 0,
}) => {
  const res = await admin({
    url: `${baseUrl(shopId)}/byExport`,
    params: {
      limit,
      start,
    },
  })
  return res.data
}

// GET /{ShopId}/memberHistory/{id}/fileSignedUrl
export const GetHistoryFileSignedUrl = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `${baseUrl(shopId)}/${id}/fileSignedUrl`,
  })
}
